import { useState, useEffect, useRef } from "react";
import { File, File_object } from "./download_link"

export class Repo_info {
    user
    name
};
export function Repo({ repo, filter }) {
    const [pages, setPage] = useState([]);
    const [request_finished, set_request_finished] = useState(false);
    const [error_info, set_error_info] = useState(null);

    const fetched = useRef(false);
    const target_repo = useRef(repo);
    const [result, set_result] = useState([]);

    if (repo !== target_repo.current) {
        target_repo.current = repo;
    }


    const fetch_infos = () => {
        console.log('fetching')
        const repo = target_repo.current;
        if (!fetched.current) {
            fetched.current = true;
            let promi = fetch(`https://api.github.com/repos/${repo.user}/${repo.name}/releases/latest`);
            promi.then(
                (data) => {
                    // console.log(data, typeof (data));
                    return data.json();
                }
            ).then(
                j => {
                    // console.log(j);
                    const assets = j['assets'];
                    let urls = assets.map((asset) => {
                        const f = new File_object();
                        f.url = asset['browser_download_url'];
                        f.name = asset['name'];
                        f.size = asset['size'];
                        return f;
                    });
                    set_request_finished(true);
                    set_result(urls);
                });
        }
    };

    useEffect(fetch_infos, [target_repo]);

    const filter_links = () => {
        const urls = result.filter(
            (url) => {
                return url.name.toLowerCase().includes(filter.toLowerCase());
            });
        setPage(urls);
        if (urls.length === 0) {
            set_error_info(<>
                <p>Empty result</p>
            </>);
        } else {
            set_error_info(null);
        }
    };
    function download(url, name, open_in_new_tab) {
        const anchor = document.createElement('a');
        anchor.href = url;
        if(open_in_new_tab){
            anchor.target="_blank"
        }
        anchor.download = name ? name : url.split('/').pop();
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
    useEffect(filter_links, [filter, result]);

    const download_all = () => {
        result.forEach(r => {
            let url = r.url;
            download(url,null,true);
        })
    }

    return (
        <section>
            <h2>
                {repo.name} <button onClick={download_all}>Download all</button>
            </h2>
            <p>
                {request_finished ? pages.map(page => <File key={page.url} file={page} />) : <p>Sending request.</p>}
                {error_info !== null ? error_info : <></>}
            </p>
        </section>
    )
}
