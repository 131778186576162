import './App.css';
import { useState, useEffect, useRef } from "react";
import { Repo, Repo_info } from "./github_repo"

function App() {
  const [filter_value, set_filter_value] = useState('');

  const [repos, set_repos] = useState([]);
  const [input_name, set_input_name] = useState('');
  const [input_repo, set_input_repo] = useState('');
  const has_init_finished = useRef(false);


  function add_repo(user, name) {
    const repo = new Repo_info();
    repo.user = user;
    repo.name = name;
    set_repos(prev => {
      let result = [...prev, repo];
      localStorage.setItem('repos', JSON.stringify(result));
      return result;
    });
  }

  useEffect(() => {
    if (has_init_finished.current) {
      return;
    }
    has_init_finished.current = true;

    try {
      const lists = JSON.parse(localStorage.getItem('repos'));
      if (lists.length !== 0) {
        set_repos(lists);
      }
    } catch (e) {
      console.log(e);
    }

  }, [has_init_finished]);

  return (
    <div className="App">

      <div>
        Add new repo: <br />
        User: <input name='user' value={input_name} onInput={value => set_input_name(value.target.value)} />
        Repo: <input name='repo' value={input_repo} onInput={value => set_input_repo(value.target.value)} />
        <button onClick={() => { add_repo(input_name, input_repo) }}>
          Add
        </button>
      </div>
      <label>
        Filter input: <input name="filter_input" onChange={value => set_filter_value(value.target.value)} />
      </label>
      {
        repos.map(repo => (<Repo repo={repo} filter={filter_value} key={repo.name}></Repo>))
      }
    </div >
  );
}

export default App;
