
import { format_file_size } from "./text_format"

export class File_object {
    name
    url
    size
};

export function File({ file }) {
    return (
        <section>
            <a className="App-link" href={file.url} download={file.name}>{file.name}</a> <span>[{format_file_size(file.size)}]</span>
        </section>
    );
}